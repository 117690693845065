import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Windy = () => {
  const [screenHeight] = useState(window.innerHeight - 10);

  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <iframe
            title="windy-map1"
            width="100%"
            height={screenHeight / 2}
            src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=km/h&zoom=7&overlay=uvindex&product=cams&level=surface&lat=46.823&lon=17.864&detailLat=46.883&detailLon=17.70799999999997&marker=true&message=true"
          ></iframe>
        </Col>
        <Col md={6}>
          <iframe
            title="windy-map2"
            width="100%"
            height={screenHeight / 2}
            src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=km/h&zoom=5&overlay=gust&product=ecmwf&level=surface&lat=46.883&lon=17.708&detailLat=46.883&detailLon=17.70799999999997&marker=true&message=true"
          ></iframe>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <iframe
            title="windy-map3"
            width="100%"
            height={screenHeight / 2}
            src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=km/h&zoom=7&overlay=satellite&product=satellite&level=surface&lat=47.093&lon=17.238&detailLat=46.87662152939771&detailLon=17.707214355468754&marker=true"
          ></iframe>
        </Col>
        <Col md={6}>
          <iframe
            title="windy-map4"
            width="100%"
            height={screenHeight / 2}
            src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=km/h&zoom=7&overlay=ptype&product=ecmwf&level=surface&lat=47.093&lon=17.238&detailLat=46.87662152939771&detailLon=17.707214355468754&marker=true"
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Windy;
