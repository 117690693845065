import React, { useEffect } from "react";

const Radar: React.FC = () => {
  const generateRadarUrl = () =>
    `https://www.idokep.hu/radar/radar.mp4?${new Date().getTime()}`;

  const refreshVideo = () => {
    const radarVideo = document.getElementById(
      "radarVideo"
    ) as HTMLVideoElement | null;
    const lastUpdate = document.getElementById(
      "last-update"
    ) as HTMLElement | null;

    if (radarVideo) {
      radarVideo.src = generateRadarUrl();
      radarVideo.load();
    }

    if (lastUpdate) {
      lastUpdate.innerHTML = new Date().toString();
    }
  };

  const enterFullScreen = (vid: string) => {
    const element = document.getElementById(vid) as HTMLElement & {
      mozRequestFullScreen?: () => Promise<void>;
      webkitRequestFullscreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };

    if (!element) {
      console.error(`Element with id ${vid} not found`);
      return;
    }

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    }
  };

  useEffect(() => {
    refreshVideo();
    const interval = setInterval(refreshVideo, 120000); // 120000 milliseconds = 2 minutes
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <>
      <video id="radarVideo" width="640" height="480" autoPlay muted loop>
        <source src={generateRadarUrl()} type="video/mp4" />
      </video>
      <br />
      <button onClick={() => enterFullScreen("radarVideo")}>
        Teljes képernyő
      </button>
      <br />
      <br />
      <div id="last-update"></div>
    </>
  );
};

export default Radar;
