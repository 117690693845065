export enum RiasztasImage {
  Short = "riaszt_1-3_transp.png",
  Daily = "riaszt_24_transp.png",
  Long = "riaszt_48_transp.png",
}

export enum TerkepUrl {
  Szel = "https://www.idokep.hu/terkep/hu/szelterkep3.webm",
  Felho = "https://cdn.idokep.hu/terkep/hu970/felhokep2.webm",
  Vihar = "https://met.hu/idojaras/tavaink/balaton/viharjelzes/main.php",
}
