import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./alert.css";
import { RiasztasImage, TerkepUrl } from "../types/Enum";

const Alert = () => {
  const [screenHeight] = useState(window.innerHeight);

  const [riasztasUrl, setRiasztasUrl] = useState(RiasztasImage.Short.valueOf());
  const [, setImageNum] = useState(0);
  const [viharjelzesUrl, setViharjelzesUrl] = useState(
    TerkepUrl.Vihar.valueOf()
  );
  const [lastUpdate, setLastUpdate] = useState("N/A");
  const [actualTime, setActualTime] = useState("N/A");

  const szelterkepRef = useRef<HTMLVideoElement>(null);
  const felhoterkepRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const minutelyInterval = setInterval(() => {
      const szelterkep = szelterkepRef.current;
      const felhoterkep = felhoterkepRef.current;
      const timestamp = new Date().getTime();

      if (szelterkep) {
        szelterkep.src = `${TerkepUrl.Szel}?t=${timestamp}`;
        szelterkep.load();
        szelterkep.addEventListener("canplay", () => szelterkep.play());
      }

      if (felhoterkep) {
        felhoterkep.src = `${TerkepUrl.Felho}?t=${timestamp}`;
        felhoterkep.load();
        felhoterkep.addEventListener("canplay", () => felhoterkep.play());
      }

      setViharjelzesUrl(TerkepUrl.Vihar + "?t=" + timestamp);
      setLastUpdate(new Date().toString());
    }, 60000);

    return () => clearInterval(minutelyInterval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const timestamp = new Date().getTime();

      setImageNum((prevImageNum) => {
        const newImageNum = prevImageNum >= 2 ? 0 : prevImageNum + 1;

        switch (newImageNum) {
          case 0:
            setRiasztasUrl(RiasztasImage.Short.valueOf() + "?t=" + timestamp);
            break;
          case 1:
            setRiasztasUrl(RiasztasImage.Daily.valueOf() + "?t=" + timestamp);
            break;
          case 2:
            setRiasztasUrl(RiasztasImage.Long.valueOf() + "?t=" + timestamp);
            break;
          default:
            break;
        }

        return newImageNum;
      });
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const secondInterval = setInterval(() => {
      setActualTime(
        `${new Date().getFullYear()}/${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}/${String(new Date().getDate()).padStart(
          2,
          "0"
        )} ${String(new Date().getHours()).padStart(2, "0")}:${String(
          new Date().getMinutes()
        ).padStart(2, "0")}:${String(new Date().getSeconds()).padStart(2, "0")}`
      );
    }, 1000);

    return () => clearInterval(secondInterval);
  }, []);

  return (
    <div className="radar-container">
      <Container fluid>
        <Row>
          <Col md={6}>
            <video ref={szelterkepRef} width={"100%"} autoPlay muted loop>
              <source src={TerkepUrl.Szel.valueOf()} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Col>
          <Col md={6}>
            <video ref={felhoterkepRef} width={"100%"} autoPlay muted loop>
              <source src={TerkepUrl.Felho.valueOf()} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="d-flex justify-content-center">
            <img
              id="riasztas"
              alt="riasztas"
              src={riasztasUrl}
              height={screenHeight / 2 - 75}
            ></img>
          </Col>
          <Col md={6}>
            <iframe
              id="viharjelzes"
              title="viharjelzes"
              src={viharjelzesUrl}
              width={"100%"}
              height={"200px"}
              allowFullScreen={true}
              style={{
                filter: "invert(100%)",
                //paddingLeft: 75,
                paddingTop: 30,
                //transform: "scale(1.2)", // Zoomolás 1.5x méretre
                //transformOrigin: "0 0", // Az átméretezés kiinduló pontja (bal felső sarok)
              }}
            ></iframe>

            <div style={{ paddingTop: 20, fontSize: 45 }}>
              <span>{actualTime}</span>
            </div>
            <div style={{ paddingTop: 35 }}>
              Az oldal utolsó frissítésének időpontja: {lastUpdate}{" "}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Alert;
