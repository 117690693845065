import React from "react";
import Windy from "./pages/Windy";
import Alert from "./pages/Alert";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Radar from "./pages/Radar";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/windy" element={<Windy />} />
        <Route path="/radar" element={<Radar />} />
        <Route path="/riasztas" element={<Alert />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
